import { EcCustomProps } from './types'
import { registerApplication as registerSingleSpaApplication } from 'single-spa'
import { importSpa } from '../warehouse'

import RouteRecognizer from 'route-recognizer'

const excludeRouter = new RouteRecognizer()

// these URLs are excluded because we only want the onboarding checklist available when a user is logged in
const excludedUrls = [
  '/companyCode',
  '/companyCode/*',
  '/sign-in',
  '/sign-in/*'
]

excludedUrls.forEach((route) => {
  excludeRouter.add([
    {
      path: route.toLowerCase(),
      handler: 'ec-payroll-onboarding-checklist-spa'
    }
  ])
})

const matchesRoute = (route: string) => {
  const matches = excludeRouter.recognize(route.toLowerCase())
  return matches && matches.length > 0
}

const registerPayrollOnboardingChecklistSpa = (customProps: EcCustomProps) => {
  registerSingleSpaApplication({
    name: 'ec-payroll-onboarding-checklist-spa',
    activeWhen: (location) => !matchesRoute(location.pathname),
    app: () => importSpa('ec-payroll-onboarding-checklist-spa'),
    customProps
  })
}

export default registerPayrollOnboardingChecklistSpa
