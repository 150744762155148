const clearLegacyPageBody = () => {
  const el = document.getElementById('page-legacy-body')
  if (el) el.innerHTML = ''
}

const isOnLegacyAspxPage = () => {
  /*
  the purpose of this check to to prevent client side navigation
  from a such as Admin>Client -> Dashboard,
  where the lingering CSS from the aspx page
  conflicts with the spa's CSS

  in the future, we may want be more granular
  with this if we find well behaved aspx pages
  that we want to client navigate from.

  an alternate check would be to look at the specific css files
  loaded into the head and decide based on that.
  */

  const pathname = window.location.pathname
  const isAspx = pathname.endsWith('.aspx')
  return isAspx
}

export { clearLegacyPageBody, isOnLegacyAspxPage }
