import { allClientPaths, allServerPaths } from '../../applications/spa-configs'
import makeEcNavigate from './makeEcNavigate'
import getHrefFromEvent from './getHrefFromEvent'
import { hasFeature } from '../../initializers/launchDarkly'

const getIsEnabled = () => {
  // since we are checking the FF on a navigation event,
  //  we accept the risk of false negatives.
  // (worst case, a page reload when there didn't need to be one)
  // as there is likely not yet anything on screen
  //  for the user to click on when the client is initializing
  return hasFeature('ec-platform-client-side-navigation-interception')
}

const {
  ecNavigate, //
  clientSideNavigate,
  canClientNavigate
} = makeEcNavigate(allClientPaths, allServerPaths)

const onLinkClick = (event: MouseEvent) => {
  const isEnabled = getIsEnabled()

  if (isEnabled) {
    const href = getHrefFromEvent(event)

    if (canClientNavigate(href)) {
      event.preventDefault()
      clientSideNavigate(href)
    }
  }
}

document.body.addEventListener('click', onLinkClick)

const isSingleSpaEvent = (event: any): boolean => {
  if (!event) return false
  return !!event.singleSpa
}

const getHref = (): string | false => {
  const fullHref = window.location.href
  const origin = window.location.origin

  if (fullHref.startsWith(origin)) {
    return fullHref.slice(origin.length)
  }
  return false
}

/*
popstate intercepts forward and back browser events.
in the case that a user
  - was on a server page
  - singleSpa navigated to a client page
  - has clicked back to go back to a server page
then we need to reload the page
in order to show the server content
*/

const getPathChanged = (prevHref: string | false, href: string | false) => {
  if (prevHref === false || href === false) return true

  try {
    const prevPath = prevHref.split('#')[0] || ''
    const path = href.split('#')[0] || ''

    return path && prevPath !== path
  } catch {
    return false
  }
}

const state = {
  prevHref: getHref()
}

window.addEventListener('popstate', (event) => {
  // we get the previous href for comparison
  const prevHref = state.prevHref

  // this is the new href
  const href = getHref()

  // we store the path so we can access it next time
  state.prevHref = href

  // the path hasn't actually changed,
  // so we believe this to be a `#route` change
  // so we no-op
  if (!getPathChanged(prevHref, href)) return

  // the FF for client side interception is disabled, so we no-op
  if (!getIsEnabled()) return

  /*
  if the event is a single spa navigate event,
  then either it came from ecNavigate
  and has already been checked against the client/server paths
  or a call-site used the raw singleSpa.navigateToUrl(), in which case
  it is the callsites duty to ensure we are going to client-safe location.

  this intercept is only concerned with forward and back events from the browser
  */
  if (isSingleSpaEvent(event)) return

  // href is not in our origin
  if (href === false) return

  if (!canClientNavigate(href)) {
    // reloading to get new server content
    window.location.reload()
  }
})

export default ecNavigate
