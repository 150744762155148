import { spaNames } from '../../applications/spa-configs'

const isSpa = (str: unknown): boolean => {
  if (typeof str === 'string') {
    return spaNames.includes(str)
  }
  return false
}

type Params = Record<string, unknown> | undefined

const getParam = (params: Params, key: string): string | null => {
  if (!params) return null

  const val = params[key] || null

  if (typeof val === 'string') {
    return val
  }

  return null
}

const getCompanyCode = (params: Params): string | null => {
  // companyCode is conventionally written in camelCase, and is done so in spa-config.
  // however we lowerCase the entire url so when checking we use lowerCase as well
  return getParam(params, 'companycode') || getParam(params, 'client')
}

export { isSpa, getParam, getCompanyCode }
