import { registerApplication } from 'single-spa'
import { EcCustomProps } from './types'
import { environmentConfig } from '../helpers'

import { importSpa } from '../warehouse/import-spa'

const registerDevToolsSpa = (customProps: EcCustomProps) => {
  if (environmentConfig.isProduction) {
    return
  }

  registerApplication({
    name: 'spa-dev-tools',
    activeWhen: () => true,
    app: () => importSpa('spa-dev-tools'),
    customProps
  })
}
export default registerDevToolsSpa
