import {
  start as singleSpaStart,
  triggerAppChange,
  addErrorHandler,
  AppError
} from 'single-spa'

import { tryReload } from './error-handler'

function handleError(error: AppError) {
  tryReload(error, triggerAppChange)
}

export function start() {
  console.debug('starting spa warehouse')

  addErrorHandler(handleError)

  singleSpaStart()
}
