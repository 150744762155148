import type { ToastUser } from '@toasttab/ec-banquet-props'
import { environmentConfig } from '../helpers'
import attachFullStory from './scripts/fullstory'

export const initializeFullstory = (user: ToastUser) => {
  const { label, isLocal } = environmentConfig

  if (isLocal) {
    console.info(
      'local environment detected, skipping fullstory initialization'
    )
    return
  }

  const {
    isEmployeeZero,
    isPosUser,
    staticRoleNames,
    userType,
    client,
    name,
    userUuid
  } = user

  //If we are on the login page we may not have a user, so we don't want to actually attach full story yet
  if (userUuid !== undefined) {
    attachFullStory()

    const fs = window['FS']

    if (fs.identify) {
      fs.identify(userUuid, {
        accountName: client,
        env: label,
        displayName: `${name} - ${client}`,
        isEmployeeZero,
        isPosUser,
        staticRoleNames,
        userType
      })
    }
  }
}
