import type { ToastUser } from '@toasttab/ec-banquet-props'
import { CustomerUserInfo } from '@toasttab/authentication-utils'

import { environmentConfig } from '../helpers'
import attachHeap from './scripts/heap'

const HEAP_PRODUCTION_KEY = '1811500362'

export const initializeHeap = (
  user: ToastUser,
  navVersion?: string,
  posUser?: CustomerUserInfo
) => {
  const { isProduction } = environmentConfig

  if (isProduction) {
    attachHeap(HEAP_PRODUCTION_KEY)
    identify(user, navVersion, posUser)
  } else {
    console.info(
      'non-production environment detected, skipping heap initialization'
    )
  }
}

export const identify = (
  user: ToastUser,
  navVersion?: string,
  posUser?: CustomerUserInfo
) => {
  const heap = window.heap
  if (heap && heap.identify) {
    heap.identify(user.userUuid)
  }

  if (heap && heap.addUserProperties) {
    heap.addUserProperties({
      customerUuid: user.customerUuid,
      employeeUuid: user.employeeUuid,
      employeeZeroType: user.employeeZeroType,
      homeState: user.homeState,
      isEmployeeZero: user.isEmployeeZero,
      isPosUser: user.isPosUser,
      posUserGuid: posUser?.guid,
      isToastAdmin: user.isUserSuperAdmin || user.isUserSuperAdminLight,
      isToastEmployee: user.userType === 'toast_internal',
      preferredLanguage: user.language,
      roles: user.staticRoleNames,
      userType: user.userType,
      payrollNavVersion: navVersion
    })
  }
}
