import 'core-js/stable'
import 'focus-visible'

import 'regenerator-runtime/runtime'

/**
 * A more detailed explanation as to why this exists can be found here
 * https://github.com/toasttab/ec-banquet-root/pull/36
 */
try {
  //@ts-ignore
  Function._validateParams = () => {}
  //@ts-ignore
  Function.validateParameters = () => {}
} catch {
  console.info('NOOPing MicrosoftAjax Function not available')
}
