import {
  makeBanquetRootEcLocalStorage,
  makeBanquetRootEcSessionStorage,
  makeBanquetRootEcCompanyLocalStorage,
  makeBanquetRootEcCompanySessionStorage
} from '@toasttab/ec-banquet-root-storage'

import type {
  EcLocalStorage,
  EcSessionStorage,
  EcCompanyLocalStorage,
  EcCompanySessionStorage
} from '@toasttab/ec-banquet-root-storage'

import { makeEcFeatureFlagOverrides } from './ecFeatureFlagOverrides'

import { overridesEnabled } from '../../utils/overrides'

const ecLocalStorage = makeBanquetRootEcLocalStorage()
const ecSessionStorage = makeBanquetRootEcSessionStorage()
const ecCompanyLocalStorage = makeBanquetRootEcCompanyLocalStorage()
const ecCompanySessionStorage = makeBanquetRootEcCompanySessionStorage()

if (overridesEnabled) {
  // we only expose these functions for overrides
  // in environments that support overrides
  makeEcFeatureFlagOverrides(ecLocalStorage)
}

export type {
  EcLocalStorage,
  EcSessionStorage,
  EcCompanyLocalStorage,
  EcCompanySessionStorage
}

export {
  ecLocalStorage,
  ecSessionStorage,
  ecCompanyLocalStorage,
  ecCompanySessionStorage
}
