import * as singleSpa from 'single-spa'
import RouteRecognizer, { Results } from 'route-recognizer'
import { clearLegacyPageBody, isOnLegacyAspxPage } from './navigation-helpers'
/*
When navigating to location that matches `allClientPaths`,
we know that the page contains only spa-rendered (no server rendered) elements
and therefore can use singleSpaNavigate rather than a full page refresh.

We intercept clicks on <a> tags in check if the path being navigated to
matches the specified client paths.

If so, we call `preventDefault()` to prevent the full page navigation,
and instead use singleSpaNavigate.

When doing so, we call `clearLegacyPageBody`
to clean up any server rendered content that as left on the page

the data data attribute `data-no-ec-navigate` can be used
to prevent the ecNavigation on a link even if it does match a clientPath

ex: <a href="/toast/dashboard" data-no-ec-navigate>Dashboard</a>

*/

const hasMatch = (results?: Results) => !!(results && results.length > 0)

const makeEcNavigate = (allClientPaths: string[], allServerPaths: string[]) => {
  const clientPathrouter = new RouteRecognizer()
  const serverPathrouter = new RouteRecognizer()

  allClientPaths.forEach((path) => {
    clientPathrouter.add([
      { path: path.toLowerCase(), handler: 'is-client-path' }
    ])
  })
  allServerPaths.forEach((path) => {
    serverPathrouter.add([
      { path: path.toLowerCase(), handler: 'is-server-path' }
    ])
  })

  const checkIsClientRoute = (route: string | undefined): route is string => {
    if (!route) return false
    const client = clientPathrouter.recognize(route.toLowerCase())
    const server = serverPathrouter.recognize(route.toLowerCase())

    const hasClientMatch = hasMatch(client)
    const hasServerMatch = hasMatch(server)

    if (hasServerMatch) {
      if (hasClientMatch) {
        console.warn(
          `${route} matches both the client AND server route config. This is OK but it does not support client side routing. A full page redirect will occur.`
        )
      }

      return false
    }

    return hasClientMatch
  }

  const canClientNavigate = (url: string | undefined): url is string => {
    return (
      checkIsClientRoute(url) && // we must be going to fully client rendered page
      !isOnLegacyAspxPage() // we must not be coming from a legacy aspx page with hostile CSS
    )
  }

  const clientSideNavigate = (url: string) => {
    clearLegacyPageBody()
    singleSpa.navigateToUrl(url)
  }

  const ecNavigate = (url: string) => {
    if (canClientNavigate(url)) {
      clientSideNavigate(url)
    } else {
      window.location.href = url
    }
  }
  return {
    ecNavigate,
    clientSideNavigate,
    canClientNavigate
  }
}

export default makeEcNavigate
