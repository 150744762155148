import registerDevToolsSpa from './registerDevToolsSpa'
import registerNavigationSpa from './registerNavigationSpa'
import { register } from '../warehouse'
import { EcCustomProps } from './types'
import spaConfigs from './spa-configs'
import registerPayrollOnboardingChecklistSpa from './registerPayrollOnboardingChecklistSpa'
import registerPayrollTaxCenterSpa from './registerPayrollTaxCenterSpa'
import registerPayrollChatbotSpa from './registerPayrollChatbotSpa'

const registerStandardSpas = (customProps: EcCustomProps) =>
  spaConfigs.forEach((config) => register(config, customProps))

const registerAllEcSpas = (customProps: EcCustomProps) => {
  registerDevToolsSpa(customProps)
  registerNavigationSpa(customProps)
  registerPayrollChatbotSpa(customProps)
  registerPayrollOnboardingChecklistSpa(customProps)
  registerPayrollTaxCenterSpa(customProps)
  registerStandardSpas(customProps)
}

export { registerStandardSpas, registerAllEcSpas }
