import RouteRecognizer from 'route-recognizer'
import { globalRouter } from '../shared-resources/getPathCompanyCode/getPathCompanyCode'

const createApplicationHandler = (application: string, paths: string[]) => {
  const router = new RouteRecognizer()

  const activeWhen = (location: Pick<Location, 'pathname'>) => {
    const matches = router.recognize(location.pathname.toLowerCase())

    if (!matches || matches.length === 0) {
      return false
    }

    const handlers = matches.slice(0).map((m) => m.handler)

    return handlers.includes(application)
  }

  paths.forEach((path) => {
    const entry = [{ path: path.toLowerCase(), handler: application }]

    //this can be used to check if a single instance an application is mounted
    router.add(entry)

    //this is used to track ALL routes
    globalRouter.add(entry)
  })

  return { activeWhen }
}

export { createApplicationHandler }
