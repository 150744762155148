const getMetaElement = (name: string) => {
  // the types on querySelector aren't smart enough to differentiate by what kind of element you'll get back so we need an as
  const tag = document.querySelector(`meta[name="${name}"]`) as HTMLMetaElement

  if (!tag) return null
  return tag
}

const getAllowedHosts = (): string[] => {
  // parses into the meta content of the page and checks
  // if the current host matches the list of allowed hosts for overrides

  try {
    const tag = getMetaElement('import-map-overrides-domains')

    if (!tag) return []

    const str = tag.content || ''

    const [allowList, hostsStr] = str.split(':')

    if (allowList !== 'allowlist') return []

    if (!(typeof hostsStr === 'string')) return []

    return hostsStr.split(',')
  } catch {
    return []
  }
}
const allowedHosts = getAllowedHosts()

const overridesEnabled = allowedHosts.includes(window.location.host)

export { overridesEnabled, allowedHosts }
