import { LDClient } from 'launchdarkly-js-client-sdk'
import type { ToastUser } from '@toasttab/ec-banquet-props'

declare global {
  interface Window {
    LDPromise: Promise<LDClient>
    isPendoSetupComplete: boolean
    heap: {
      identify?: (id: string) => void
      //https://docs.google.com/spreadsheets/d/1nmVOjpLh2WihVAeqj6c9-Y6yKhvodd3TW9Efg_KrzSs/edit#gid=701878382
      addUserProperties?: (user: {
        customerUuid: string
        employeeUuid: string
        employeeZeroType: string
        homeState: string
        isEmployeeZero: boolean
        isPosUser: boolean
        posUserGuid?: string
        isToastAdmin: boolean
        preferredLanguage: string
        roles: string
        // timeZone: string
        userType: string
        isToastEmployee: boolean
        payrollNavVersion?: string
      }) => void
    }
  }
}

const getUser = () => window.Toast.user
const getConfig = () => window.Toast

const hasUserSession = (user: ToastUser) => {
  /**
   * This type is kind of wrong. We don't ALWAYS have a user uuid, sometimes is can actually be undefined for anonymous users
   */
  if ((user.userUuid ?? null) === null) {
    return false
  } else {
    return true
  }
}
const getLaunchDarklyClientKey = () => window.Toast.launchDarklyClientKey

export { getUser, getConfig, getLaunchDarklyClientKey, hasUserSession }
