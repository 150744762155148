import compact from 'lodash/compact'
import { makeEcTitleManager } from '@toasttab/ec-banquet-root-title-manager'
import { environmentConfig } from '../../helpers'

const APP_NAME = 'Toast Payroll'

const envStr = environmentConfig.envIdentifier

const suffix = envStr ? `${APP_NAME} [${envStr}]` : APP_NAME

const ecTitleManager = makeEcTitleManager({
  renderTitle: (titles) => {
    const combined = titles.join(' › ').trim()
    return compact([combined, suffix]).join(' - ')
  }
})

export { ecTitleManager }
