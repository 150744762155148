import { registerApplication } from 'single-spa'
import { createApplicationHandler } from './router'
import { EcSpaConfig, EcCustomProps } from '../applications/types'
import { importSpa } from './import-spa'

export function register(spaConfig: EcSpaConfig, customProps: EcCustomProps) {
  const { name } = spaConfig

  const paths = [
    ...('serverPaths' in spaConfig ? spaConfig.serverPaths : []),
    ...('clientPaths' in spaConfig ? spaConfig.clientPaths : [])
  ]

  const app = () => importSpa(name)

  const { activeWhen } = createApplicationHandler(name, paths)

  const config = {
    name,
    activeWhen,
    app,
    customProps
  }

  registerApplication(config)

  console.debug('ec-banquet-root > registered: ', name)
}
