import { initialize, LDClient } from 'launchdarkly-js-client-sdk'
import type { ToastUser } from '@toasttab/ec-banquet-props'

import { hasUserSession } from '../utils/user'
import { ecLocalStorage } from '../shared-resources'
import { overridesEnabled } from '../utils/overrides'

/**
 *
 * This idea behind this is that we attach the promise to
 * the window then when we want to access the client in a
 * spa, we can await the promise to retrieve the client.
 *
 * A SPA (or other hook) would simply access it like:
 *
 * const flag = useFeatureFlag('ff-1', true)
 *
 * There is some missing code in ec-spa-common that goes
 * something like this:
 *
 * const useEcBanquetProps = () => useBanquetPropsBase<EcBanquetProps>()
 *
 * const useFeatureFlag = <TFlag = boolean>(flag: string, defaultValue: TFlag) => {
 *    const { getLaunchDarklyClient } = useEcBanquetProps()
 *    const { data: client } = useQuery(
 *        ['launch-darkly-client-resolver'],
 *        getLaunchDarklyClient,
 *        { suspense: true }
 *    )
 *
 *    return client.variation(flag, defaultValue)
 * }
 *
 * In most cases this will resolve immediately because it
 * initializes fine. In edge cases, the client may
 * not be ready, which is fine, we can suspend and resume
 */

let client: LDClient

export const initializeLaunchDarkly = (
  launchDarklyClientKey: string,
  user: ToastUser
) => {
  if (hasUserSession(user)) {
    client = initialize(launchDarklyClientKey, {
      key: user.email,
      email: user.email.toLowerCase(),
      custom: {
        viewCompanyCode: user.client.toLowerCase(),
        customerCreatedAt: user.customerCreatedAt
      }
    })
  } else {
    console.info('initializing launch darkly client as anonymous.')
    client = initialize(launchDarklyClientKey, {
      anonymous: true
    })
  }

  return asAsyncClient(client)
}

export function asAsyncClient(ldClient: LDClient) {
  const promise = ldClient.waitUntilReady().then(() => ldClient)
  /**
   * This provides backwards compatibility with the way SPA's
   * resolve the client.
   *
   * They check the promise attached to the window and if it exists
   * just use that. Otherwise it makes one and attached to it.
   *
   * This code will run before any SPA is able to create one so
   * therefore all SPA's should just use this version of launch darkly.
   *
   * Once everything is in place, this can be removed and the consumption
   * can be moved to the ec-spa-common hook
   */
  window.LDPromise = promise

  return promise
}

const getOverrideValue = (key: string) => {
  if (!key) return null
  try {
    const overrides = ecLocalStorage.getValue('feature-flag-local-overrides')
    return key in overrides ? !!overrides[key] : null
  } catch {
    return null
  }
}

// this is meant to be used inside of banquet-root
// specifically by the navigation interception feature
// it is not compatible with features that have types other than boolean
// and it does not handle the startup condition of an uninitialized client
// with anything more than returning false.

export const hasFeature = (key: string) => {
  const value = client?.variation?.(key, false)

  if (!overridesEnabled) return value

  try {
    return !!(getOverrideValue(key) ?? value)
  } catch {
    return value
  }
}
