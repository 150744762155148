const ENVS_CONFIG = [
  {
    label: 'prod',
    urlMatches: [],
    envIdentifier: ''
  },
  {
    label: 'qa',
    urlMatches: ['web.qastratex', 'preprod.eng.toasttab.com'],
    envIdentifier: 'PreProd'
  },
  {
    label: 'dev',
    urlMatches: ['web.devstratex'],
    envIdentifier: 'Dev'
  },
  {
    label: 'local',
    urlMatches: ['localhost', 'http://', 'payroll.eng.toastteam.com'],
    envIdentifier: 'Local'
  }
] as const

const PROD_CONFIG = ENVS_CONFIG[0]

export type EnvironmentLabel = (typeof ENVS_CONFIG)[number]['label']

export interface EnvironmentConfig {
  envIdentifier: string
  label: EnvironmentLabel
  isLocal: boolean
  isProduction: boolean
  isQA: boolean
  isDev: boolean
}

const getConfig = (url: string) => {
  const matchFromUrl = ENVS_CONFIG.find((config) => {
    const urlMatches = config.urlMatches.map((str) => str) // TS being mad about readonly arrays
    return urlMatches.some((urlMatch) => url.indexOf(urlMatch) > -1)
  })

  const match = matchFromUrl || PROD_CONFIG

  return { label: match.label, envIdentifier: match.envIdentifier }
}

const config = getConfig(window.location.href)

const { label, envIdentifier } = config

export const environmentConfig: EnvironmentConfig = {
  label,
  isProduction: label === 'prod',
  isQA: label === 'qa',
  isDev: label === 'dev',
  isLocal: label === 'local',
  envIdentifier: envIdentifier
}
