import type { ToastUser } from '@toasttab/ec-banquet-props'
import { environmentConfig } from '../helpers'
import attachPendo from './scripts/pendo'

export const initializePendo = (user: ToastUser, userIdentityGuid: string) => {
  const { isLocal } = environmentConfig

  if (isLocal) {
    console.info('local environment detected, skipping pendo initialization')
    return
  }

  if (window.isPendoSetupComplete) {
    console.warn(
      'ec-banquet-root attempted to initialize pendo but it was already setup'
    )
  } else {
    console.info('initializing pendo via ec-banquet-root')
    window.isPendoSetupComplete = true
    attachPendo(window.Toast.pendoClientKey, user, window, userIdentityGuid)
  }
}
