const getHrefFromEvent = (event: MouseEvent): string | undefined => {
  try {
    // if someone else has already called preventDefault()
    // then we should not consider this an attempt to navigate
    if (event.defaultPrevented) return

    const target = event.target || event.srcElement

    if (!(target instanceof Element)) return

    const anchor = target.closest('a')

    if (!anchor) return

    // if someone has added this data attribute
    // then are telling us not to intercept navigation
    if (anchor.getAttribute('data-no-ec-navigate')) return

    // if we want to open this link in a new tab,
    // then it necessarily has to be a server navigation
    if (anchor.getAttribute('target') === '_blank') return

    const href = anchor.getAttribute('href')

    if (href) return href
  } catch (error) {
    console.log('link detection error: ', error)
  }
}

export default getHrefFromEvent
