import RouteRecognizer from 'route-recognizer'
import compact from 'lodash/compact'

import { isSpa, getParam, getCompanyCode } from './helpers'

type Result = {
  code: string | null
  isSpaMatch: boolean // whether the spa's matched the URL
}

const globalRouter = new RouteRecognizer()
const companyCodeRouter = new RouteRecognizer()

companyCodeRouter.add([
  { path: '/mvc/:companyCode/:wildcard', handler: 'mvc' },
  { path: '/mvc/:companyCode/:wildcard/*', handler: 'mvc' },
  { path: '/:companyCode/:wildcard', handler: 'other' },
  { path: '/:companyCode/:wildcard/*', handler: 'other' }
])

const getSpaPathClientCode = (url: string): Result => {
  const matches = globalRouter.recognize(url)

  if (!matches || matches.length === 0) {
    return { code: null, isSpaMatch: false }
  }

  const spaMatches = matches.slice(0).filter((match) => isSpa(match.handler))

  const isSpaMatch = spaMatches.length >= 1

  const matchCodes = spaMatches.map((match) => getCompanyCode(match.params))

  const code = compact(matchCodes)[0] || null

  return { code, isSpaMatch }
}

const INCLUDE_OTHER = false // todo: figure out if there are other know URLs that need to be handled

const getGeneralCompanyCode = (url: string) => {
  const matches = companyCodeRouter.recognize(url)

  if (!matches || matches.length === 0) {
    return null
  }

  const codes = compact(
    matches.slice(0).map((match) => {
      const { handler, params } = match
      if (handler === 'mvc' || (INCLUDE_OTHER && handler === 'other')) {
        return getParam(params, 'companyCode')
      }
      return null
    })
  )

  return codes[0] || null
}

const getPathCompanyCode = (route: string | undefined): string | null => {
  if (!route) return null
  const url = route.toLowerCase()

  const { code, isSpaMatch } = getSpaPathClientCode(url)

  if (isSpaMatch) {
    return code
  }

  // if it's a spa match, even if doesn't have a companyCode, we don't evaluate try the general companyCodeChecker
  // this allows spas like `staff-tools` or `user-migration` to own routes and not have false positives when they have deep routes

  return getGeneralCompanyCode(url)
}

export { getPathCompanyCode, globalRouter }
