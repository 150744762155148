import { environmentConfig } from '../helpers'
import * as Sentry from '@sentry/browser'
import { makeSpaKey } from '@toasttab/ec-sentry'
import pkg from '../../package.json'

const __ROOT_NAME__ = 'ec-banquet-root'
const __FALLBACK_DSN__ =
  'https://2ac21c87753443fe96a1ef139b48d1a3@sentry.io/1866872'
const __ROOT_PATH__ = System.resolve(__ROOT_NAME__)

const fileToModuleMap = new Map<string, string>([
  [__ROOT_PATH__, __ROOT_NAME__]
])

//this should be replaced by a call from banquet-runtime-modules
sessionStorage.setItem(makeSpaKey(__ROOT_NAME__), __FALLBACK_DSN__)

export const initializeSentry = (spas: string[]) => {
  const { label, isLocal } = environmentConfig
  const release = `${pkg.name}@${process.env.PKG_VERSION}`

  if (isLocal) {
    console.info('local environment detected, skipping sentry initialization')
    return
  }

  if (!Sentry) {
    return
  }

  spas.forEach((app) => {
    try {
      const file = System.resolve(app)
      fileToModuleMap.set(file, app)
    } catch (ex) {
      console.warn(
        `unable to resolve specifier: '${app}'. As a result we will not map errors from this app to it's proper Sentry DSN`
      )
    }
  })
  try {
    console.info('initializing sentry')
    Sentry.init({
      dsn: __FALLBACK_DSN__,
      environment: label as string,
      release,
      transport: Sentry.makeMultiplexedTransport(
        Sentry.makeFetchTransport,
        function dsnFromConfig({ getEvent }) {
          const event = getEvent()

          if (event === undefined) {
            console.warn('undefined event, not sure why, ignoring')
            return [__FALLBACK_DSN__]
          }

          let spa = event?.tags?.spa ?? undefined

          if (spa === undefined) {
            const originator = event?.exception?.values
              ?.at(0)
              ?.stacktrace?.frames?.at(-1)?.filename

            if (originator) {
              spa = fileToModuleMap.get(originator)
            }
          } else {
            console.info(
              `found "spa" tag, using ${spa.toString()} as the key to find the Sentry DSN`
            )
          }

          if (typeof spa !== 'string') {
            console.info('The tag "spa" must be a string. Found:', spa)
            return [__FALLBACK_DSN__]
          } else if (spa !== undefined) {
            //this should be replaced by a function from banquet-runtime-modules
            const dsn = sessionStorage.getItem(`sentry:${spa}`)

            if (dsn !== null) {
              return [dsn]
            }
          }

          console.warn(
            'unable to categorize event, falling back to default sentry project',
            event
          )

          return [__FALLBACK_DSN__]
        }
      )
    })
  } catch (ex) {
    console.error('unable to initialize sentry', ex)
  }
}
