import './polyfills'
import './styles.css'

import { checkLocale } from '@toasttab/buffet-pui-locale-utilities'
import { Currency } from '@toasttab/buffet-pui-types'
import { initAuthClient } from '@toasttab/ec-authentication'
import { Intl } from '@toasttab/banquet-types'

import { start } from './warehouse'
import {
  ecCompanyLocalStorage,
  ecCompanySessionStorage,
  ecLocalStorage,
  ecNavigate,
  ecSessionStorage,
  ecTitleManager,
  getPathCompanyCode,
  i18n
} from './shared-resources'

import { getConfig, getLaunchDarklyClientKey, getUser } from './utils/user'
import {
  initializeFullstory,
  initializeHeap,
  initializeLaunchDarkly,
  initializePendo,
  initializeSentry
} from './initializers'
import { registerAllEcSpas } from './applications/registerAllEcSpas'
import type { EcCustomProps } from './applications/types'
import { overridesEnabled } from './utils/overrides'
import spaConfigs from './applications/spa-configs'

const defaultLogout = () =>
  console.warn(
    'logout of POS unavailable because POS session was not available'
  )

const bootstrap = async () => {
  /**
   * First things first, we have a user.
   *
   * They may not be logged in but we WILL have a valid user object.
   */
  const user = getUser()
  const { auth0BaseUrl, auth0ClientId, payrollLoginCookieDomain } = getConfig()

  //We can initialize launch darkly and get back a promise (we don't want to await it)
  const launchDarklyClientPromise = initializeLaunchDarkly(
    getLaunchDarklyClientKey(),
    user
  )

  // these are non-blocking initializations
  initializeSentry(spaConfigs.map((spa) => spa.name))
  initializeFullstory(user)

  const intl: Intl = {
    currency: Currency.USD,
    language: checkLocale(i18n.language),
    // @ts-ignore banquet root is using v21, but they are api compatible
    // todo, we should consider upgrading to v23
    i18next: i18n
  }

  const customProps: EcCustomProps = {
    ec: {
      user,
      auth0BaseUrl,
      auth0ClientId,
      payrollLoginCookieDomain,
      launchDarklyClientPromise,
      ecNavigate,
      ecLocalStorage,
      ecSessionStorage,
      ecCompanyLocalStorage,
      ecCompanySessionStorage,
      ecTitleManager,
      i18n,
      getPathCompanyCode,
      overridesEnabled
    },
    i18n: intl,
    token: null,
    posUser: undefined,
    logout: defaultLogout
  }

  const { auth: authClient } = await initAuthClient({
    originsWithAuth: [window.location.origin]
  }).catch((error) => {
    console.warn(
      'unable to initialize auth client, no POS auth information will be available',
      error
    )

    return { auth: null }
  })

  if (authClient !== null) {
    const token = await authClient.getTokenSilently().catch((error: string) => {
      console.warn('unable to retrieve POS token', error)
      return null
    })

    customProps.token = token
    customProps.posUser = authClient.userInfo
    customProps.logout = authClient.logout
  }

  registerAllEcSpas(customProps)

  //we want to initialize pendo after we know if we have a posUser.guid
  initializePendo(user, customProps?.posUser?.guid ?? user.userUuid)

  const navVersion = ecLocalStorage?.getValue('dev-navigation-version')
  initializeHeap(user, navVersion, customProps.posUser)

  start()
}

console.groupCollapsed('ec-banquet-root:init')

bootstrap().then(console.groupEnd)
