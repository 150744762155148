import { AppError } from 'single-spa'
import * as Sentry from '@sentry/browser'

/**
 * This purpose of this error handler is to make an attempt, for a certain
 * set of error messages, to reload the application.
 *
 * If you are looking to add retry logic, add your error message part to
 * the errorMessageFormatter.
 *
 * This file is designed to be used with the "addErrorHandler" from single-spa
 */

function formatValidMessages(app: string) {
  return [`single-spa-css: While mounting '${app}', loading CSS from URL`]
}

/**
 * Keeps track of the attempts to reload the application
 */
const reloadMap = new Map<string, boolean>()

/**
 * This is a designed for testing to make sure we clear the map for
 * each test run. It's very unlikely this would ever be used in a
 * real environment.
 */
function _reset() {
  reloadMap.clear()
}

function captureException(appError: AppError, spa: string) {
  Sentry.captureException(appError, (scope) => scope.setTag('spa', spa))
}

/**
 * This attempts to reload the application based on the message and
 * if it has been attempted in the past. The original purpose of this
 * is to make sure that if the CSS does not load, we give it another
 * try.
 * @param appError
 * @param reloadFn
 */
function tryReload(appError: AppError, reloadFn: () => void) {
  const { message, appOrParcelName: app } = appError

  if (isErrorMessageReloadable(message, app)) {
    const attempted = reloadMap.get(app) ?? false

    if (!attempted) {
      reloadMap.set(app, true)
      console.debug(`attempting to reload ${app}`)
      reloadFn()
    } else {
      console.debug(
        `writing error to sentry due to multiple instances of the same error`
      )
      captureException(appError, app)
    }
  } else {
    captureException(appError, app)
  }
}

/**
 * Checks against a known format of message to decide if it should attempt to reload itself
 *
 * @param errorMessage
 * @param app
 * @returns if the application can attempt to reload itself
 */
function isErrorMessageReloadable(errorMessage: string, app: string) {
  return formatValidMessages(app).some((message) =>
    errorMessage.includes(message)
  )
}

export { tryReload, formatValidMessages, _reset }
