import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n.use(LanguageDetector).init({
  fallbackLng: 'en-US',
  interpolation: {
    escapeValue: false
  },
  detection: {
    order: ['htmlTag', 'navigator']
  },
  resources: {}
})

export default i18n
