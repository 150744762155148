import type { EcLocalStorage } from '@toasttab/ec-banquet-root-storage'

import omit from 'lodash/omit'

declare global {
  interface Window {
    ecFeatureFlagOverrides?: {
      getOverrides: () => Record<string, boolean>
      getOverride: (flag: string) => boolean
      setOverride: (flag: string, value: boolean) => void
      clearOverrides: () => void
    }
  }
}

// provides js console access,
// specifically for the use case of when logged out,
// to set feature flags for the client

const makeEcFeatureFlagOverrides = (ecLocalStorage: EcLocalStorage) => {
  const getOverrides = () => {
    return ecLocalStorage.getValue('feature-flag-local-overrides')
  }

  const getOverride = (flag: string) => {
    return ecLocalStorage.getValue('feature-flag-local-overrides')[flag]
  }

  const setOverride = (flag: string, value: boolean = false) => {
    ecLocalStorage.setValue('feature-flag-local-overrides', (overrides) => {
      if (value === null) {
        return omit(overrides, flag)
      }
      return { ...overrides, [flag]: !!value }
    })
  }

  const clearOverrides = () => {
    ecLocalStorage.setValue('feature-flag-local-overrides', {})
  }

  window.ecFeatureFlagOverrides = {
    getOverrides,
    getOverride,
    setOverride,
    clearOverrides
  }
}

export { makeEcFeatureFlagOverrides }
